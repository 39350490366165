export const SET_QUIZ_ANSWERS = 'user/SET_QUIZ_ANSWERS';
export const SET_USER = 'user/SET_USER';
export const SET_CODE = 'user/SET_CODE';
export const SET_FIRST_VISIT_DATE = 'user/SET_FIRST_VISIT_DATE';
export const SET_ERROR_MSG = 'user/SET_ERROR_MSG';
export const SET_USER_IP = 'user/SET_USER_IP';
export const SET_GEOLOCATION = 'user/SET_GEOLOCATION';
export const SET_SELECTED_PLAN = 'user/SET_SELECTED_PLAN';
export const SET_UPSELL_PRODUCTS = 'user/SET_UPSELL_PRODUCTS';
export const SET_SUB_CONFIG = 'user/SET_SUB_CONFIG';
export const SET_TERMS_AND_CONDITIONS = 'user/SET_TERMS_AND_CONDITIONS';
export const SET_COUPON_DATA = 'user/SET_COUPON_DATA';
export const SET_COUPON_APPLIED = 'user/SET_COUPON_APPLIED';
export const SET_DISCOUNTED_SUBS = 'user/SET_DISCOUNTED_SUBS';
export const SET_SELECTED_SUB_INDEX = 'user/SET_SELECTED_SUB_INDEX';
export const LOCALISED_COUNTRIES = ['CA', 'NZ', 'AU', 'GB', 'MX', 'US'];
export const LOCALISED_EU_CONTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'IS',
  'LI',
  'NO',
];

import { createActionCreator } from '@reduxify/utils';

import {
  SET_CODE,
  SET_COUPON_APPLIED,
  SET_COUPON_DATA,
  SET_DISCOUNTED_SUBS,
  SET_ERROR_MSG,
  SET_FIRST_VISIT_DATE,
  SET_GEOLOCATION,
  SET_QUIZ_ANSWERS,
  SET_SELECTED_PLAN,
  SET_SELECTED_SUB_INDEX,
  SET_SUB_CONFIG,
  SET_TERMS_AND_CONDITIONS,
  SET_UPSELL_PRODUCTS,
  SET_USER,
  SET_USER_IP,
} from './constants';
import { IUserState } from './types';

export const setGeolocation =
  createActionCreator<IUserState['geolocation']>(SET_GEOLOCATION);
export const setUserIp =
  createActionCreator<IUserState['user_ip']>(SET_USER_IP);
export const setQuizAnswers =
  createActionCreator<IUserState['quiz_answers']>(SET_QUIZ_ANSWERS);
export const setUser = createActionCreator<IUserState['user']>(SET_USER);
export const setCode = createActionCreator<IUserState['code']>(SET_CODE);
export const setFirstVisitDate =
  createActionCreator<IUserState['code']>(SET_FIRST_VISIT_DATE);
export const setErrorMsg =
  createActionCreator<IUserState['error_msg']>(SET_ERROR_MSG);
export const setSelectedPlan =
  createActionCreator<IUserState['selected_plan']>(SET_SELECTED_PLAN);
export const setUpsellProducts =
  createActionCreator<IUserState['upsell_products']>(SET_UPSELL_PRODUCTS);
export const setSubscriptionConfig =
  createActionCreator<IUserState['subscription_config']>(SET_SUB_CONFIG);
export const setTermsAndConditions = createActionCreator<
  IUserState['termsAndConditions']
>(SET_TERMS_AND_CONDITIONS);
export const setCouponData =
  createActionCreator<IUserState['coupon_data']>(SET_COUPON_DATA);
export const setCouponApplied =
  createActionCreator<IUserState['coupon_applied']>(SET_COUPON_APPLIED);
export const setDiscountedSubs =
  createActionCreator<IUserState['discounted_subs']>(SET_DISCOUNTED_SUBS);
export const setSelectedSubIndex = createActionCreator<
  IUserState['selected_sub_index']
>(SET_SELECTED_SUB_INDEX);

import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GeolocationModel, LeadModel } from '@frontend/api-wrapper';
import { handleError } from 'utils/error';
import { Api } from 'apis/api';
import {
  getLocalisedProduct,
  getValidatedIsoCountry,
} from 'utils/localization';

import {
  setCode,
  setCouponApplied,
  setCouponData,
  setDiscountedSubs,
  setErrorMsg,
  setGeolocation,
  setQuizAnswers,
  setSelectedPlan,
  setSelectedSubIndex,
  setSubscriptionConfig,
  setTermsAndConditions,
  setUpsellProducts,
  setUser,
  setUserIp,
} from './actions';
import { IUserState } from './types';
import axios from 'axios';
import { AppState } from 'state/types';

export const updateSubscriptionConfig =
  (subscription_config: IUserState['subscription_config']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setSubscriptionConfig(subscription_config));

export const updateQuizAnswers =
  (answers: IUserState['quiz_answers']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    const queryParams = new URLSearchParams(window.location.search);
    const quizToken = queryParams.get('steps');
    const codeParam = window.location.pathname?.split('/')?.pop();
    const pathCode = queryParams.get('code') || codeParam;

    if (quizToken) {
      const encodedState = queryParams.get('steps');
      if (encodedState) {
        try {
          const decodedState = decodeURIComponent(atob(encodedState));
          const parsedState = JSON.parse(decodedState);
          const newState = JSON.stringify({ ...parsedState, ...answers });
          const newEncodedState = encodeURIComponent(newState);
          queryParams.set('steps', btoa(newEncodedState));
          const newSearchParams = queryParams.toString();
          const newUrl = `${window.location.pathname}?${newSearchParams}${window.location.hash}`;
          window.history.replaceState(null, '', newUrl);
        } catch (error) {
          console.error('Error parsing state from URL:', error);
        }
      }
    }
    if (!quizToken && !(pathCode && pathCode.length === 32)) {
      const serializedState = JSON.stringify(answers);
      const encodedState = encodeURIComponent(serializedState);

      queryParams.set('steps', btoa(encodedState));
      const newSearchParams = queryParams.toString();

      const newUrl = `${window.location.pathname}?${newSearchParams}${window.location.hash}`;
      window.history.replaceState(null, '', newUrl);
    }
    dispatch(setQuizAnswers(answers));
  };

export const updateTermsAndConditions =
  (tAndC: IUserState['termsAndConditions']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setTermsAndConditions(tAndC));

export const updateUser =
  (user: IUserState['user']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setUser(user));

export const updateCode =
  (code: IUserState['code']) =>
  (dispatch: ThunkDispatch<{}, void, Action<IUserState['code']>>) =>
    dispatch(setCode(code));

export const updateSelectedPlan =
  (product: IUserState['selected_plan']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>, getState: () => AppState) => {
    const state = getState();

    const couponApplied = state.user.coupon_applied;
    const discountedSubs = state.user.discounted_subs;
    const selected_sub_index = state.user.selected_sub_index;

    const discountedSub =
      couponApplied && discountedSubs && selected_sub_index !== null
        ? discountedSubs[selected_sub_index]
        : null;

    if (product && typeof (window as any).kiloCheckout !== 'undefined') {
      const localisedProduct = getLocalisedProduct(product);
      const price =
        discountedSub && discountedSub.promoPrice
          ? discountedSub.promoPrice
          : localisedProduct?.hasTrial
          ? localisedProduct.discountedTrialPrice
          : localisedProduct?.finalPrice;

      const kiloProduct = new (window as any).KiloProduct(
        product.key,
        product.name,
        price,
      );
      (window as any).kiloCheckout.selectProduct(kiloProduct);
    }

    dispatch(setSelectedPlan(product));
  };

export const updateUpsellProducts =
  (products: IUserState['upsell_products']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setUpsellProducts(products));

export const updateErrorMsg =
  (errorMsg: IUserState['error_msg']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setErrorMsg(errorMsg));

export const createLead =
  (data: LeadModel) => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.createLead(data)
      .then(({ code }) => {
        dispatch(updateCode(code));
        return code;
      })
      .catch((e: any) => {
        handleError(e);
        throw e;
      });

export const updateLead =
  (code: string, data: LeadModel) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.updateLead(code, data).catch((e: any) => {
      handleError(e);
      throw e;
    });

export const fetchGeolocation =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    new Promise<void>((resolve, reject) => {
      Api.getUserGeolaction()
        .then((geolocation: GeolocationModel) => {
          axios
            .get('https://api64.ipify.org?format=json')
            .then(response => {
              const userIp = response.data?.ip;
              dispatch(setUserIp(userIp));
            })
            .catch(ipError => {
              console.error('Error fetching IP address:', ipError);
            });
          if (typeof window !== 'undefined') {
            if (!geolocation.iso_country) {
              resolve();
              return;
            }

            const urlParams = new URLSearchParams(window?.location.search);
            const isoCountry = getValidatedIsoCountry(geolocation.iso_country);
            localStorage.setItem('userLocale', isoCountry);

            const currentLocale = urlParams.get('locale');
            if (currentLocale !== isoCountry) {
              if (currentLocale) {
                urlParams.set('locale', isoCountry);
              } else {
                urlParams.append('locale', isoCountry);
              }

              const newSearch = urlParams.toString();
              window?.history.replaceState(
                {},
                '',
                `${window?.location.pathname}?${newSearch}`,
              );
            }

            dispatch(setGeolocation(geolocation));
            resolve(); // Resolve the promise after dispatching
          }
        })
        .catch(error => {
          handleError(error);
          reject(error); // Reject the promise on error
        });
    });

// export const fetchGeolocation =
//   () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
//     Api.getUserGeolaction()
//       .then((geolocation: GeolocationModel) => {
//         if (!geolocation.iso_country) return;

//         const urlParams = new URLSearchParams(window.location.search);
//         const isoCountry = getValidatedIsoCountry(geolocation.iso_country);

//         if (isoCountry !== urlParams.get('locale')) {
//           urlParams.set('locale', isoCountry);
//           window.location.search = urlParams.toString();
//         }

//         dispatch(setGeolocation(geolocation));
//       })
//       .catch(handleError);

export const fetchUser =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const codeParam = window.location.pathname?.split('/')?.pop();
      const pathCode = params.get('code') || codeParam;
      const quizCode = params.get('steps');

      if (pathCode && pathCode.length === 32) {
        Api.fetchLead<{ email: string; quiz_answers: unknown }>(pathCode)
          .then(({ email, code, quiz_answers }) => {
            if (email) {
              dispatch(updateUser({ email, password: '' }));
            }

            if (quiz_answers) {
              dispatch(updateQuizAnswers(quiz_answers));
            }

            dispatch(updateCode(code));
          })
          .catch(handleError);
      }
      if (quizCode && !(pathCode && pathCode.length === 32)) {
        const decodedQuizState = decodeURIComponent(atob(quizCode));
        const parsedState = JSON.parse(decodedQuizState);
        dispatch(updateQuizAnswers(parsedState));
      }
    }
  };

export const updateCouponData =
  (couponData: IUserState['coupon_data']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setCouponData(couponData));

export const updateCouponApplied =
  (couponApplied: IUserState['coupon_applied']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setCouponApplied(couponApplied));

export const updateDiscountedSubs =
  (discountedSubs: IUserState['discounted_subs']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setDiscountedSubs(discountedSubs));

export const updateSelectedSubIndex =
  (selectedSubIndex: IUserState['selected_sub_index']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setSelectedSubIndex(selectedSubIndex));

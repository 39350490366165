export enum ENVs {
  'production' = 'production',
  'development' = 'development',
}

const NAME = 'Walking Yoga';

const ENV =
  process.env.NODE_ENV === 'production' ? ENVs.production : ENVs.development;
const GTM_TAG = process.env.REACT_APP_GTM_TAG || 'GTM-TQZHPJZD';
const API_TOKEN =
  process.env.REACT_APP_API_TOKEN || '6ce3a04e-61fc-4972-9edc-12fc43899b9a';
const BASE_URL =
  process.env.REACT_APP_API_URL ||
  'https://wellness-a-team-effecto-funnel-api-sandbox.cl2.kilo.live/api/';
const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS || '';
const FIREBASE_REGISTER_URL =
  process.env.REACT_APP_FIREBASE_REGISTER_URL ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/registerInWeb';
const REACT_APP_FIREBASE_USER_HAS_PAYED_URL =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAYED_URL ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/userHasPaid';
const PATIENT_APP_BASE_URL = process.env.REACT_APP_PATIENT_APP_BASE_URL || '';
const HYROS_TOKEN = process.env.REACT_APP_HYROS_TOKEN || '';

const FIREBASE_MAILING =
  process.env.REACT_APP_FIREBASE_MAILING ||
  'https://us-central1-walking-yoga-app-staging.cloudfunctions.net/sendEmail';

const KLAVIYO_KEY = process.env.REACT_APP_KLAVYIO_KEY;

const FACEBOOK_PIXEL_ID = process.env.GATSBY_FACEBOOK_PIXEL_ID;

const FACEBOOK_API_ACCESS_TOKEN = process.env.GATSBY_FACEBOOK_API_ACCESS_TOKEN;

const FACEBOOK_API_VERSION = process.env.GATSBY_FACEBOOK_API_VERSION;

const CAPI_FUNCTION_URL = process.env.FACEBOOK_CAPI_URL;

const DATADOG_TOKEN = process.env.DATADOG_TOKEN;

const TRACKING_ENABLED = true;

const MW_REGISTER_URL =
  process.env.FIREBASE_MW_REGISTER_URL ||
  'https://us-central1-mindwayhealth-app-staging.cloudfunctions.net/register';

const REACT_APP_FIREBASE_USER_HAS_PAID_URL_MW =
  process.env.REACT_APP_FIREBASE_USER_HAS_PAID_URL_MW ||
  'https://us-central1-mindwayhealth-app-staging.cloudfunctions.net/userHasPaid';

//stuff

const BLOCKED_COUNTRIES = ['LT'];

const configs = {
  [ENVs.development]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    FIREBASE_MAILING,
    KLAVIYO_KEY,
    FACEBOOK_PIXEL_ID,
    FACEBOOK_API_ACCESS_TOKEN,
    FACEBOOK_API_VERSION,
    TRACKING_ENABLED,
    CAPI_FUNCTION_URL,
    DATADOG_TOKEN,
    MW_REGISTER_URL,
    REACT_APP_FIREBASE_USER_HAS_PAID_URL_MW,
    BLOCKED_COUNTRIES,
  },
  [ENVs.production]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
    FIREBASE_REGISTER_URL,
    PATIENT_APP_BASE_URL,
    HYROS_TOKEN,
    REACT_APP_FIREBASE_USER_HAS_PAYED_URL,
    FIREBASE_MAILING,
    KLAVIYO_KEY,
    FACEBOOK_PIXEL_ID,
    FACEBOOK_API_ACCESS_TOKEN,
    FACEBOOK_API_VERSION,
    TRACKING_ENABLED,
    CAPI_FUNCTION_URL,
    DATADOG_TOKEN,
    MW_REGISTER_URL,
    REACT_APP_FIREBASE_USER_HAS_PAID_URL_MW,
    BLOCKED_COUNTRIES,
  },
};

export const config = configs[ENV];

import { createReducer, merge, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import {
  setCode,
  setCouponApplied,
  setCouponData,
  setDiscountedSubs,
  setErrorMsg,
  setFirstVisitDate,
  setGeolocation,
  setQuizAnswers,
  setSelectedPlan,
  setSelectedSubIndex,
  setSubscriptionConfig,
  setTermsAndConditions,
  setUpsellProducts,
  setUser,
  setUserIp,
} from './actions';
import { IUserState } from './types';
import { getUnixDate } from 'utils/functions';

const quiz_answers = createReducer<IUserState['quiz_answers']>(
  {},
  reduce(setQuizAnswers, merge),
);
const user = createReducer<IUserState['user']>(null, reduce(setUser, set));
const code = createReducer<IUserState['code']>(null, reduce(setCode, set));
const firstVisitDate = createReducer<IUserState['firstVisitDate']>(
  getUnixDate(),
  reduce(setFirstVisitDate, set),
);
const geolocation = createReducer<IUserState['geolocation']>(
  null,
  reduce(setGeolocation, set),
);
const user_ip = createReducer<IUserState['user_ip']>(
  null,
  reduce(setUserIp, set),
);
const selected_plan = createReducer<IUserState['selected_plan']>(
  null,
  reduce(setSelectedPlan, set),
);
const upsell_products = createReducer<IUserState['upsell_products']>(
  [],
  reduce(setUpsellProducts, set),
);
const error_msg = createReducer<IUserState['error_msg']>(
  null,
  reduce(setErrorMsg, set),
);

const subscription_config = createReducer<IUserState['subscription_config']>(
  null,
  reduce(setSubscriptionConfig, set),
);

const termsAndConditions = createReducer<IUserState['termsAndConditions']>(
  true,
  reduce(setTermsAndConditions, set),
);

const coupon_data = createReducer<IUserState['coupon_data']>(
  null,
  reduce(setCouponData, set),
);

const coupon_applied = createReducer<IUserState['coupon_applied']>(
  false,
  reduce(setCouponApplied, set),
);

const discounted_subs = createReducer<IUserState['discounted_subs']>(
  null,
  reduce(setDiscountedSubs, set),
);

const selected_sub_index = createReducer<IUserState['selected_sub_index']>(
  null,
  reduce(setSelectedSubIndex, set),
);

export default combineReducers<IUserState>({
  code,
  firstVisitDate,
  geolocation,
  quiz_answers,
  user,
  error_msg,
  user_ip,
  selected_plan,
  upsell_products,
  termsAndConditions,
  subscription_config,
  coupon_data,
  coupon_applied,
  discounted_subs,
  selected_sub_index,
});

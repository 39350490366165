import React, { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from 'styles/breakpoints';

import ProgressCount from './ProgressCount';
import { Color } from 'utils/theme';
import Logo from 'assets/icons/logo.svg';
import BackButton from 'assets/icons/back-button.svg';

type LogoVariant = 'left' | 'center';

interface HeaderProps {
  countTotal?: number;
  count?: number;
  hideCount?: boolean;
  onBack?: () => void;
  logoVariant?: LogoVariant;
  RightComponent?: ReactNode;
  sticky?: boolean;
  color?: Color;
  hasDivider?: boolean;
  maxWidth?: string;
  hideBackButton?: boolean;
}

export const HEADER_HEIGHT = 4;
export const HEADER_HEIGHT_MOBILE = 3.75;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const Container = styled.div<{ color?: Color; hasDivider?: boolean }>`
  height: ${HEADER_HEIGHT}rem;
  display: flex;
  justify-content: center;
  background-color: ${({ theme, color }) =>
    theme.colors[color ? color : 'light0']};
  padding: 0 1rem;
  ${({ hasDivider }) => hasDivider && 'border-bottom: 1px solid#F0F0F0;'}
  @media ${mobile} {
    height: ${HEADER_HEIGHT_MOBILE}rem;
  }
`;

const ContentContainer = styled.div<{
  logoVariant: LogoVariant;
  maxWidth?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '28.75rem')};
  width: 100%;
  position: relative;
`;

const Section = styled.div<{ justifyContent?: 'left' | 'center' | 'right' }>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
`;

const LogoSection = styled(Section)<{ justifyContent: string }>`
  ${({ justifyContent }) =>
    justifyContent === 'center' &&
    `right: 50%;
  transform: translateX(50%);
  position: absolute;`};
`;

const Gap = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}rem;

  @media ${mobile} {
    height: ${HEADER_HEIGHT_MOBILE}rem;
  }
`;

const LogoStyled = styled(Logo)`
  height: 1.5rem;
  width: 5.92758rem;
`;

const StyledBackButton = styled(BackButton)`
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  @media ${tablet} {
    width: 1rem;
    height: 1rem;
  }
`;

const Header: FC<HeaderProps> = ({
  logoVariant = 'center',
  countTotal,
  count,
  hideCount,
  onBack,
  RightComponent,
  sticky = true,
  color,
  hasDivider,
  maxWidth,
  hideBackButton = false,
}) => {
  const visibleBackButton = useMemo(
    () => logoVariant === 'center',
    [logoVariant],
  );

  const renderRightSectionContent = () => {
    if (logoVariant === 'left') {
      return RightComponent;
    }
    return (
      typeof countTotal === 'number' &&
      typeof count === 'number' &&
      !hideCount && <ProgressCount total={countTotal} count={count} />
    );
  };

  return (
    <>
      {sticky ? (
        <>
          <Wrap>
            <Container color={color} hasDivider={hasDivider}>
              <ContentContainer logoVariant={logoVariant} maxWidth={maxWidth}>
                {visibleBackButton && !hideBackButton && (
                  <Section justifyContent="left">
                    {onBack && <StyledBackButton onClick={onBack} />}
                  </Section>
                )}
                <LogoSection justifyContent={logoVariant}>
                  <LogoStyled />
                </LogoSection>
                <Section justifyContent="right">
                  {renderRightSectionContent()}
                </Section>
              </ContentContainer>
            </Container>
          </Wrap>
          <Gap />
        </>
      ) : (
        <Container color={color} hasDivider={hasDivider}>
          <ContentContainer logoVariant={logoVariant} maxWidth={maxWidth}>
            {visibleBackButton && !hideBackButton && (
              <Section justifyContent="left">
                {onBack && <StyledBackButton onClick={onBack} />}
              </Section>
            )}
            <LogoSection justifyContent={logoVariant}>
              <LogoStyled />
            </LogoSection>
            <Section justifyContent="right">
              {renderRightSectionContent()}
            </Section>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default React.memo(Header);

import {
  AxonItem,
  GAItem,
  GoogleAnalyticsMainItem,
} from 'types/analyticsTypes';
import { Subscription } from 'types/product';
import { getLocalisedProduct } from 'utils/localization';

export const convertKiloProductToGAItemMain = (
  product: any,
  promoSub?: Subscription,
): GoogleAnalyticsMainItem => {
  const finalProduct = promoSub ?? getLocalisedProduct(product);

  const {
    sku,
    name,
    finalPrice,
    currencyId,
    hasTrial,
    discountedTrialPrice,
    promoPrice,
  } = finalProduct as Subscription;

  const price = promoPrice
    ? promoPrice
    : hasTrial
    ? discountedTrialPrice || '0'
    : finalPrice || '0';

  const category = 'Subscription';

  return {
    sku,
    name,
    category,
    price: price,
    quantity: 1,
    currency: currencyId,
  };
};

export const convertKiloProductToGAItem = (
  product: any,
  purchase_type: string,
  coupon?: any,
  quantity?: any,
  promoSub?: Subscription,
): GAItem => {
  const finalProduct = promoSub ?? getLocalisedProduct(product);
  const {
    name,
    finalPrice,
    sku,
    key,
    currency,
    discount,
    regularPrice,
    hasTrial,
    discountedTrialPrice,
    promoPrice,
  } = finalProduct as Subscription;

  const price =
    Number(
      Number(
        promoPrice
          ? promoPrice
          : hasTrial
          ? discountedTrialPrice || '0'
          : finalPrice || '0',
      ).toFixed(2),
    ) || 0;

  return {
    key,
    item_id: sku,
    item_name: name,
    affiliation: 'WalkingYoga',
    purchase_type,
    coupon,
    currency,
    discount: Number(discount),
    price: price,
    original_price: Number(Number(regularPrice).toFixed(2) || 0),
    quantity: quantity || 1,
    type: product.type,
  };
};

export const convertKiloProductAppLovin = (
  product: any,
  promoSub?: Subscription,
): AxonItem => {
  const finalProduct = promoSub ?? getLocalisedProduct(product);

  const {
    sku,
    name,
    finalPrice,
    currencyId,
    hasTrial,
    discountedTrialPrice,
    promoPrice,
  } = finalProduct as Subscription;

  const promoPriceAsNumber = Number(promoPrice);

  const price = promoPrice
    ? promoPriceAsNumber
    : hasTrial
    ? Number(discountedTrialPrice) || 0
    : Number(finalPrice);

  return {
    item_id: sku,
    item_name: name,
    price: price,
    quantity: 1,
    item_category_id: 2092,
  };
};
